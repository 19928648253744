module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.directvplans.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"chatEnabled":true,"analyticsId":"GTM-P5NB6DB","brandTokens":["DTV","DTVB","DTVBP","ATT"],"mapiBrandToken":"DTVR","siteName":"directvplans","alternateName":"directvplans.com","siteURL":"https://www.directvplans.com","defaultTitleTemplate":"","defaultPhone":"8339511626","phoneSymbol":".","defaultPromoCode":"112568","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046061","vwoProjectID":"894940","datasetManagerAPIKey":"607eafce3e0b4e91b69b1468efc33c1c"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"directvplans","short_name":"directvplans","start_url":"/","background_color":"#","theme_color":"#","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28b32ba719b499399d7c565bd488bbca"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"qqr6xvi"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
